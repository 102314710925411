import * as React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
// Forty
import Banner from '../components/Banner'
import twilioPic from '../images/twilio.jpg'
import markdownPic from '../images/markdown.jpg'
import countdownPic from '../images/countdown.jpg'
import journalPic from '../images/journal.jpg'
import malariaPic from '../images/malaria.jpg'
import machinePic from '../images/machine-learning.jpg'

const IndexPage = () => (
  <Layout>
    <Seo title="Portfolio" />
    <Banner />
    <div id="main" style={{ backgroundColor: '#1a0035' }}>
      <section className="inner">
        <header className="major">
          <h2>Latest Projects</h2>
        </header>
      </section>

      <section id="one" className="tiles">
        <article style={{ backgroundImage: `url(${machinePic})` }}>
          <header className="major">
            <h3>Model Match Pro</h3>
            <p>
              Tool for comparing responses from multiple Large Language Models.
            </p>
          </header>
          <a
            href="https://github.com/jbarrfitz/model-match-pro"
            className="link primary"
          />
          {/* <Link to="/landing" className="link primary"></Link> */}
        </article>
        <article style={{ backgroundImage: `url(${malariaPic})` }}>
          <header className="major">
            <h3>DataRx - Malaria Edition</h3>
            <p>
              Statistical analysis of real-world genetic data used for
              researching malaria treatment options.
            </p>
          </header>
          <a
            href="https://github.com/DataRx-Malaria-Edition/DataRx-Malaria-Edition"
            className="link primary"
          />
          {/* <Link to="/landing" className="link primary"></Link> */}
        </article>
        <article style={{ backgroundImage: `url(${twilioPic})` }}>
          <header className="major">
            <h3>Facility Access</h3>
            <p>SMS-based access to a facility based on an event schedule.</p>
          </header>
          <a
            href="https://github.com/jbarrfitz/facility-access"
            className="link primary"
          />
          {/* <Link to="/landing" className="link primary"></Link> */}
        </article>
        <article style={{ backgroundImage: `url(${countdownPic})` }}>
          <header className="major">
            <h3>Countdown App</h3>
            <p>Example project using React / Webpack.</p>
          </header>
          <a
            href="https://github.com/jbarrfitz/session-clock"
            className="link primary"
          />
        </article>
        <article style={{ backgroundImage: `url(${markdownPic})` }}>
          <header className="major">
            <h3>Markdown Previewer</h3>
            <p>Real-time preview of markdown text</p>
          </header>
          <a
            href="https://github.com/jbarrfitz/markdown-previewer"
            className="link primary"
          />
        </article>
        <article style={{ backgroundImage: `url(${journalPic})` }}>
          <header className="major">
            <h3>Quote Generator</h3>
            <p>Generate unique quotes</p>
          </header>
          <a
            href="https://github.com/jbarrfitz/random-quote-machine"
            className="link primary"
          />
        </article>
      </section>
    </div>
  </Layout>
)

export default IndexPage
